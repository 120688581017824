
.heading-con {
    display: flex;
    height: 100vh;
    justify-content: space-evenly;
    margin-bottom: 50px;
}

.heading {
    font-size: 3vmin;
    display: flex;
    flex-direction: column;
    margin: auto 0;
}

.heading > h4 {
    margin-top: -30px;
}

.heading > p {
    width: 40vmin;
    text-align: justify;
    text-justify: inter-word;
}

.nav-li-con {
    display: flex;
    justify-content: space-around;
}

.mid-nav-links {
    list-style: none;
    text-decoration: none;
    padding: 6px;
    font-size: large;
    transition: .2s ease-in-out;
    border-radius: 5px;
    cursor: pointer;
}

.mid-nav-links:hover {
    font-size: larger;
    box-shadow: 0px 0px 5px 0px;
    transition: .2s ease-in-out;
    border-radius: 5px;
}

.repo-con {
    padding: 10px;
    border: 1px solid black;
    margin: 10px;
    border-radius: 5px;
    text-align: center;
    flex-wrap: wrap;
}

.repo-box {
    display: flex;
    margin: 10px;
    padding: 10px;
    overflow: scroll;
}

a {
    text-decoration: none;
}

.hidden {
    display: none;
}

.repo-box-con {
    border: 1px solid black;
    margin-top: 20px;
}

.aboutMe-con {
    text-align: center;
    display: flex;
    margin-top: 20px;
    padding: 20px;
}

.headshot-con {
    margin: auto 5vmin;
    display: flex;
}

.headshot {
    height: 50vmin; 
    width: auto;
    border-radius: 100px;
    zoom: 110%;
    
}

.icon-con {
    display: flex;
    justify-content: space-evenly;
    transform: scale(1.05);
    padding: 10px 0px;
    margin: 10px 0px;
}

.icon-con:hover {
    color: #D3FBD8;
}


