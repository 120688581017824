.work-con {
    display: flex;
    justify-content: center;
    height: 50vh;
}

.resume-con {
    text-align: justify;
    padding-left: 10px;
    width: 30vw;
    align-items: center;
    text-justify: inter-word;
    margin-top: 80px;
}



.lineElement-con {
    display: inline-flex;
    flex-direction: column;
    margin-right: 50px;
    margin-top: 80px;
}

.dot {
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: white;
}

.line {
    height: 200px;
}

