
.project-con {
    background-color: #2d2e32;

}


.project-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    height: 100vh;
    margin: 90px 50px;
}

.project-inner-con {
    display: flex;
    border: 1px solid black;
    padding: 10px 20px;
    height: 40vmin;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 1px black;
    transition: .3s ease-in-out;
}


p {
    font-size: 2vmin;
}

.project-hr {
    width: 50%;
    height: 10px;
    border-radius: 5px;
    transition: .3s ease-in-out;
    position: relative;
    top: 100px;
    filter: contrast(.8);
}

.project-headline {
    filter: contrast(.8);
    position: relative;
    top: 90px;
    text-align: center;
    font-size: larger;
}