.experience-con {
    margin-bottom: 50px;
}


.exp-con {
    padding: 30px;
    margin: 20px 40px;
    display: flex;
    max-width: 80vmin;
    box-shadow: 1px 1px 5px 1px black;
    border-radius: 10px;
    transition: .3s ease-in-out;
}

.exp-con:hover {
    transform: scale(1.03);
}

.lang-icon {
    transform: scale(3);
    display: flex;
    align-items: center;
    padding: 0px 20px;
    margin-right: 70px;
    transition: .3s ease-in-out;
}

.lang-icon:hover {
    transform: scale(3.5);
}


.language-con {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.language-con > hr {
    width: 30%;
    margin: 10px 0px;
    height: 10px;
    filter: contrast(.8);
    border-radius: 5px;
}

.language-con > span {
    font-size: 3vmin;
}

