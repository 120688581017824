
/* Navbar */

.navbar-con {
    background-color: #222444;
    box-shadow: 0px 0px 5px 0px black;

}

.navbar-item {
    color: #F4F9FF;
}

.navbar-item:hover {
    box-shadow: 5px 5px 0px 0px #D3FBD8;
    border: 1px solid #D3FBD8;
}


/* Footer */

.footer-con {
    color: #FFA;
    background: #222444;
}




/* HomeCSS */

.heading-con {
    background-color: #25262a;
}

.heading {
    color: #F4F9FF;
}

.mid-nav-links {
    color: black;
}

.repo-con {
    background-color: white;
}

a {
    color: black;
}

.repo-box-con {
    background-color: grey;
}

h4 {
    color: #D3FBD8;
}


/* Language Experience */

.exp-con {
    background-color: #222444;
    color: #F4F9FF;
}

.language-con {
    color: white;
}

.language-con > hr {
    background: #4A90F9;
    border: 1px solid #25262a;
}

.language-con > span {
    color: #F4F9FF;
}


/* Work Experience */

.work-con {
    background-color: #25262a;
}

.resume-con {
    color: #F4F9FF;
}

h3 {
    color: #D3FBD8;
}

/* Project CSS */


.project-con {
    background-color: #2d2e32;
}

.project-inner-con {
    background-color: #222444;
}

a {
    color: #F4F9FF;
}

.project-hr {
    background: #4A90F9;
    border: .1px solid #25262a;
}

.project-headline {
    color: #F4F9FF;
}


@media (max-width: 767px) {

    .heading-con {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .headshot {
        margin-top: 50px;
    }

    h4 {
        padding: 10px 0px;
    }

    .resume-con {
        margin-top: -80px;
    }

    .lineElement-con {
        margin-top: -50px;
        height: 200px;
    }



}

